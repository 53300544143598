import { Helmet } from 'react-helmet';
import React from 'react';
import { graphql } from 'gatsby';

const Detail = ({ data }) => {

  //  Page
  const post = data.otherwebsites
  let redirect = `${post.field_link_to_the_page.toLowerCase()}`

  return (
    <Helmet>
      <title>{`Genesis ${post.title}`}</title>
      <meta http-equiv="refresh" content={`0; URL='${redirect}'`} />
    </Helmet>
  )
}

export default Detail;

export const query = graphql`
  query($id: String!) {
    # Other websites redirects
    otherwebsites: nodeCenter(id: { eq: $id }) {
      id
      title
      path {
        alias
      }
      field_link_to_the_page
    }
  }
`